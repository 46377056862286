// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "      <button class=\"close\">&times;</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"progress-window\">\n  <header class=\"title-bar\">\n    <strong class=\"title\">Loading data</strong>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"dismissable") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "  </header>\n\n  <section class=\"content\">\n    <div class=\"status\">\n      <span class=\"message\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"message") || (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message","hash":{},"data":data,"loc":{"start":{"line":11,"column":28},"end":{"line":11,"column":39}}}) : helper)))
    + "</span>\n      <span class=\"pct\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"progress") || (depth0 != null ? lookupProperty(depth0,"progress") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"progress","hash":{},"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":12,"column":36}}}) : helper)))
    + "</span>\n    </div>\n    \n    <div class=\"progress-bar\">\n      <div class=\"progress-bar-fill\"></div>\n    </div>\n  </section>\n</div>\n";
},"useData":true});
