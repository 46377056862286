// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header id=\"title-bar\" role=\"banner\">\n  <div class=\"title-left\">\n    <small class=\"text-muted\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"filename") || (depth0 != null ? lookupProperty(depth0,"filename") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filename","hash":{},"data":data,"loc":{"start":{"line":3,"column":30},"end":{"line":3,"column":44}}}) : helper)))
    + " &mdash; "
    + alias4(((helper = (helper = lookupProperty(helpers,"details") || (depth0 != null ? lookupProperty(depth0,"details") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"details","hash":{},"data":data,"loc":{"start":{"line":3,"column":53},"end":{"line":3,"column":66}}}) : helper)))
    + "</small>\n    <ul class=\"keys\">\n      <li class=\"viewer-key\">Grouped by <a>"
    + alias4(((helper = (helper = lookupProperty(helpers,"groupKey") || (depth0 != null ? lookupProperty(depth0,"groupKey") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"groupKey","hash":{},"data":data,"loc":{"start":{"line":5,"column":43},"end":{"line":5,"column":57}}}) : helper)))
    + "</a></li>\n      <li class=\"legend-key\">color-coded by <a>"
    + alias4(((helper = (helper = lookupProperty(helpers,"colorKey") || (depth0 != null ? lookupProperty(depth0,"colorKey") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"colorKey","hash":{},"data":data,"loc":{"start":{"line":6,"column":47},"end":{"line":6,"column":61}}}) : helper)))
    + "</a></li>\n    </ul>\n  </div>\n\n  <div class=\"title-right\">\n    <a href=\"/\" id=\"brand\">nineteen</a>\n    <button id=\"save\">Save</button>\n  </div>\n</header>\n\n<figure id=\"visualization\">\n  <div id=\"legend\"></div>\n  <div id=\"viewer\"></div>\n</figure>\n\n<div id=\"dropdowns\"></div>\n";
},"useData":true});
