// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "    <button id=\"toggle-view\" class=\"view-stack\">Stack</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <button id=\"toggle-view\" class=\"view-split\">Split</button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "Hide";
},"7":function(container,depth0,helpers,partials,data) {
    return "Show";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"tool-bar\">\n  <button class=\"block-sort sort-active\" value=\"value\"></button>\n  <button class=\"block-sort sort-reverse\" value=\"count\"></button>\n  <button id=\"search-icon\"></button>\n  <div id=\"search\">\n    <input type=\"search\" id=\"search-field\" placeholder=\"Find\" data-html2canvas-ignore/>\n    <span id=\"search-export\"></span>\n  </div>\n  <button id=\"search-in\">within <strong>all fields</strong></button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"split") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":10,"column":2},"end":{"line":14,"column":9}}})) != null ? stack1 : "")
    + "  <button id=\"toggle-counts\"><span class=\"count-icon\">#</span> <span class=\"count-label\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"counts") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":15,"column":89},"end":{"line":15,"column":126}}})) != null ? stack1 : "")
    + " counts</span></button>\n\n  <button id=\"viewer-columns\">Show in viewer</button>\n</form>\n\n<div class=\"visualizer\">\n  <div class=\"labels\"></div>\n\n  <div class=\"blocks\">\n    <div class=\"block-items\"></div>\n    <div class=\"rows\">\n      <div class=\"row-items\"></div>\n    </div>\n  </div>\n</div>\n\n<div id=\"zoom-controls\" data-html2canvas-ignore>\n  <button class=\"zoom-reset\" title=\"Actual Size\"></button>\n  <button class=\"zoom-fit\" title=\"Best Fit\"></button>\n  <button class=\"zoom-in\" title=\"Zoom In\"></button>\n  <button class=\"zoom-out\" title=\"Zoom Out\"></button>\n</div>\n";
},"useData":true});
