module.exports={
  "name": "nineteen",
  "version": "1.0.0",
  "description": "A simple application to help qualitative researchers visualize their data.",
  "main": "index.js",
  "scripts": {
    "start": "DEBUG=express:* node ./index.js",
    "test": "NODE_ENV=test PORT=3001 mocha -r ./test/spec_helper test/support/server.js test/**/*_test.js"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/UseNineteen/nineteen.git"
  },
  "author": "Theodore Pollari",
  "helpURL": "https://github.com/UseNineteen/nineteen/blob/main/README.md",
  "license": "AGPL",
  "bugs": {
    "url": "https://github.com/UseNineteen/nineteen/issues"
  },
  "homepage": "https://github.com/UseNineteen/nineteen",
  "engines": {
    "node": "16"
  },
  "dependencies": {
    "autolinker": "^0.22.0",
    "autoprefixer-stylus": "^0.8.1",
    "babel-preset-es2015": "^6.1.18",
    "babelify": "^7.2.0",
    "backbone": "^1.3.3",
    "backbone.babysitter": "^1.0.0",
    "backbone.marionette": "^2.4.3",
    "backbone.stickit": "^0.9.2",
    "backbone.wreqr": "^1.3.5",
    "body-parser": "^1.14.1",
    "browserify": "^12.0.1",
    "browserify-middleware": "^7.0.0",
    "config": "^1.17.1",
    "csv": "^0.4.6",
    "d3": "^3.5.9",
    "express": "^4.13.3",
    "express-handlebars": "^3.0.1",
    "express-session": "^1.13.0",
    "handlebars": "^4.0.4",
    "hbsfy": "^2.4.1",
    "interact.js": "^1.2.6",
    "jquery": "^2.1.4",
    "jszip": "^2.5.0",
    "lodash": "^4.17.21",
    "marionette": "5.0.0-alpha.2",
    "mime": "^1.3.4",
    "moment": "^2.10.6",
    "moment-parseformat": "^2.0.0",
    "multer": "^1.1.0",
    "normalize.css": "^3.0.3",
    "request": "^2.67.0",
    "session-file-store": "0.0.22",
    "streamifier": "^0.1.1",
    "stylus": "^0.52.4",
    "text-miner": "^1.0.5",
    "textmining": "^1.0.3",
    "underscore": "^1.13.6",
    "vinyl-buffer": "^1.0.0",
    "vinyl-source-stream": "^1.1.0",
    "winston": "^2.1.0",
    "xlsx": "^0.8.0"
  },
  "devDependencies": {
    "chai": "^3.4.1",
    "jsdom": "^8.0.0",
    "mocha": "^2.4.5",
    "nock": "^3.3.2",
    "sinon": "^1.17.2",
    "supertest": "^1.1.0",
    "watchify": "^3.6.1",
    "xmlhttprequest": "^1.8.0",
    "zombie": "^6.1.4"
  }
}
