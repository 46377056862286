// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header role=\"banner\">\n	<h1>nineteen</h1>\n	<h2>Visually explore your data</h2>\n</header>\n\n<div class=\"forms\">\n	<div class=\"container\">\n		<form action=\"/upload\" id=\"upload\" method=\"post\" enctype=\"multipart/form-data\">\n		  <h4>Use a local file</h4>\n		  <p>Most secure option. Files never leave your computer.</p>\n			<button class=\"btn btn-primary choose-file\">Choose a file</button>\n		  <input class=\"sr-only\" type=\"file\" name=\"file\" id=\"file\" accept=\".csv,.xls,.xlsx\"/>\n		</form>\n\n		<form action=\"/download\" id=\"download\" method=\"post\">\n		  <h4>Use a hosted file</h4>\n		  <p>Use any publicly accessible file on Google Drive, Dropbox, or elsewhere.</p>\n			<div class=\"input-group\">\n			  <input type=\"url\" name=\"url\" id=\"url\" placeholder=\"Paste a link to a .csv or .xlsx file\"/>\n				<span class=\"input-group-btn\"><input type=\"submit\" value=\"Go\"/></span>\n			</div>\n		</form>\n	</div>\n</div>\n\n<footer class=\"container\">\n	<ul>\n		<li><a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"helpURL") || (depth0 != null ? lookupProperty(depth0,"helpURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"helpURL","hash":{},"data":data,"loc":{"start":{"line":28,"column":15},"end":{"line":28,"column":28}}}) : helper)))
    + "\">How to use</a></li>\n		<li><a id=\"sample\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"sample") || (depth0 != null ? lookupProperty(depth0,"sample") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sample","hash":{},"data":data,"loc":{"start":{"line":29,"column":27},"end":{"line":29,"column":39}}}) : helper)))
    + "\">Try an example</a></li>\n		<li><a id=\"github\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"github") || (depth0 != null ? lookupProperty(depth0,"github") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"github","hash":{},"data":data,"loc":{"start":{"line":30,"column":27},"end":{"line":30,"column":39}}}) : helper)))
    + "\">GitHub</a></li>\n	</ul>\n</footer>\n";
},"useData":true});
